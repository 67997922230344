import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { CONFIG } from '../config';
import { AiFriendConversationDialogComponent } from '../shared-dialogs/dialogs/ai-friend-conversation-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class AiFriendService {

  constructor(
    private MatLegacyDialog: MatLegacyDialog,
    private Request: RequestService,
  ) {
  }

  startConversation() {
    this.MatLegacyDialog.open(AiFriendConversationDialogComponent, { width: '600px' });
  }

  askFriend = (question: string) => {
    return this.Request.post(`${CONFIG.api}smarton_prompt/ask_friend`, { question })
  }

}
