import {
  Component
} from '@angular/core';
import { MatLegacyDialogModule } from '@angular/material/legacy-dialog';
import { CommonModule } from '@angular/common';
import { DialogActionsComponent } from '../../common-components/standalone/dialog-actions.component';
import { PromanTextSimpleComponent } from '../../text-simple';
import { AiFriendService } from '../../services/ai-friend.service';
import { FlexLayoutModule } from 'ngx-flexible-layout';
import { Fa6Module } from '../../fa/fa6.module';
import { FormControl, UntypedFormControl } from '@angular/forms';
import { isDefinedNotNull } from '../../utils';
import { PromanPaddingDirective } from '../../shared/directives/proman-padding.directive';

declare interface ConversationMessage {
  content: string;
  isUser: boolean;
}

@Component({
  selector: 'pro-ai-friend-conversation-dialog',
  standalone: true,
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatLegacyDialogModule,
    Fa6Module,

    DialogActionsComponent,
    PromanTextSimpleComponent,

    PromanPaddingDirective
  ],
  template: `
    <div mat-dialog-content #content>
      <div class="message-container" fxLayout="column">
        @for (message of messageHistory; track $index) {
          <article fxLayout="row" fxLayoutAlign="start center">
            <fa proPadding [name]="message.isUser ? 'user' : 'brain-circuit'"></fa>
            <p>{{ message.content }}</p>
          </article>
        }
      </div>

      <pro-text-simple [value]="prompt" [config]="{ label: 'prompt', type: 'textarea', debounce: 1500 }"
                       [control]="control" (onChange)="sendPrompt($event)"></pro-text-simple>
    </div>
    <pro-dialog-actions></pro-dialog-actions>
  `,
  styles: `
      .message-container {
          overflow-y: scroll;
      }
  `
})

export class AiFriendConversationDialogComponent {
  messageHistory: ConversationMessage[] = [];
  prompt: string;
  control: UntypedFormControl;

  constructor(
    private AiFriend: AiFriendService
  ) {
    this.control = new FormControl(this.prompt);
  }

  sendPrompt(value: string) {
    if (isDefinedNotNull(value)) {
      this.prompt = value;
      this.messageHistory.push({ isUser: true, content: value });
      this.AiFriend.askFriend(value).then((result) => {
        this.prompt = null;
        this.control.reset();
        this.messageHistory.push({ isUser: false, content: result });
      });
    }
  }
}
