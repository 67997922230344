import { Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
    selector: '[proPadding]',
    standalone: true
})

export class PromanPaddingDirective {
    constructor(el: ElementRef, renderer: Renderer2) {
        renderer.addClass(el.nativeElement, 'PaddingLayout');
    }
}
